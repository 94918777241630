window.addEventListener('heyflow-screen-view', (event) => {
    if (event.detail && event.detail.screenIndex === 0) {
        var origin = document.location.href || 'not found';
        var originInput = document.querySelector('input[data-label="origin_url"]');

        if (originInput) {
            originInput.value = origin;
        }

        var userAgentInput = document.querySelector('input[data-label="user_agent"]');

        if (userAgentInput) {
            userAgentInput.value = window.navigator.userAgent;
        }
    }

    // replace month with next month
    if (event.detail && event.detail.screenID === "id-a7311507") {
        var currentDate = new Date();
        var currentMonth = (currentDate.getMonth() + 2).toString();
        var twoDigitMonth = currentMonth.padStart(2, '0');
        var startMonthLabel = document.querySelector('#id-4aeed5e6 + .option-content .label');
        var newMonthString = 'möglichst zum 01.' + twoDigitMonth + '. starten';

        startMonthLabel.innerHTML = newMonthString;
    }

    // legal overlays
    var overlayBackground = document.getElementById('popUpBackground');
    var imprintOverlay = document.getElementById('wefox-imprint');
    var imprintLink = document.querySelector('.link-list li:first-child');
    var dataProtectionLink = document.querySelector('.link-list li:last-child');
    var documentBody = document.querySelector('body');

    if (imprintLink) {
        imprintLink.addEventListener('click', function(event) {
            event.preventDefault();
            
            if (imprintOverlay && overlayBackground) {
                imprintOverlay.style.display = 'block';
                overlayBackground.style.display = 'block';
                documentBody.classList.add('wefox-sticky');

                var closeButton = document.getElementById('overlay-close-button');
                if (closeButton) {
                    closeButton.addEventListener('click', function() {
                        imprintOverlay.style.display = 'none';
                        overlayBackground.style.display = 'none';
                        documentBody.classList.remove('wefox-sticky');
                    });
                }
            }
        });
    }

    if (dataProtectionLink) {
        dataProtectionLink.addEventListener('click', function(event) {
            console.log('data protection was clicked');
        });
    }
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});


let autocomplete;

function initAutocomplete() {
  const address1Field = document.querySelector("#id-7aba4abc");

  autocomplete = new google.maps.places.Autocomplete(address1Field, {
    componentRestrictions: { country: ["de"] },
    fields: ["address_components"],
    types: ["postal_code"],
  });

  autocomplete.addListener("place_changed", fillInAddress);
}

function fillInAddress() {
  const place = autocomplete.getPlace();
  let postcode = "";
  let locality = "";

  for (const component of place.address_components) {
    const componentType = component.types[0];

    switch (componentType) {
      case "postal_code": {
        postcode = component.long_name;
        break;
      }
      case "locality": {
        locality = component.long_name;
        break;
      }
    }
  }

  document.querySelector("#id-7aba4abc").value = postcode;
  document.querySelector("#id-9807ef22").value = locality;
}

window.addEventListener("load", initAutocomplete);